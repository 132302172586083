<template>
	<el-dialog :lock-scroll="true" title="Statistici produs" class="product-stats-modal" :visible.sync="reactiveStatus" :fullscreen="true">
		<div class="center">
			<span class="sub-title">{{$t('reports.products.date-from')}} &nbsp;</span>
			<el-date-picker @change="handleDateChange" v-model="date.start" value-format="yyyy-MM-dd" type="date">
			</el-date-picker>
			&nbsp;
			<span class="sub-title">{{$t('reports.products.date-to')}} &nbsp; </span>
			<el-date-picker @change="handleDateChange" v-model="date.end" value-format="yyyy-MM-dd" type="date">
			</el-date-picker>
		</div>
		<el-tabs v-model="activeReport">
			<el-tab-pane :label="$t('reports.products.views')" name="views" :lazy="true">
				<line-chart :options="chartOptions" :chartData="reactiveChart"></line-chart>
			</el-tab-pane>
			<el-tab-pane :label="$t('reports.products.in-cart')" name="cartAdds" :lazy="true">
				<line-chart :chartData="reactiveChart"></line-chart>
			</el-tab-pane>
			<el-tab-pane :label="$t('reports.products.sells')" name="sells" :lazy="true">
				<line-chart :chartData="reactiveChart"></line-chart>
			</el-tab-pane>
			<el-tab-pane :label="$t('reports.products.favorites')" name="favorites" :lazy="true">
				<line-chart :chartData="reactiveChart"></line-chart>
			</el-tab-pane>
			<el-tab-pane :label="$t('reports.products.searches')" name="listViews" :lazy="true">
				<line-chart :chartData="reactiveChart"></line-chart>
			</el-tab-pane>
		</el-tabs>
		<div class="separator"></div>
		<div class="pieStats">
			<el-row :span="24" :gutter="20">
				<el-col :xs="24" :sm="10" :md="6" :lg="6" :xl="6" style="text-align:left">
					<div class="sub-title">
						<strong>Total: {{parseInt(pieReports['views']['total']) == 1 ? $t('reports.products.one-view') : pieReports['views']['total'] + ' ' + $t('reports.products.views-2')}}</strong>
					</div>
					<template v-if="parseInt(pieReports['views']['total']) === 0">
						<div class="inactive-chart-header">
							<span class="inactive-chart-mobile">
								<span class="inactive-chart-mobile-color"></span>{{$t('reports.products.mobile')}}</span>
							<span class="inactive-chart-desktop">
								<span class="inactive-chart-desktop-color"></span>Desktop</span>
						</div>
						<img class="inactiveChart" :src="chartInactiv" />
					</template>
					<template v-else>
						<pie-chart :options="pieOptions" :width="pieOptions.width" :height="pieOptions.height" :chartData="reactivePieChart('views')"></pie-chart>
					</template>
				</el-col>
				<el-col :xs="24" :sm="10" :md="6" :lg="6" :xl="6" style="text-align:left">
					<div class="sub-title">
						<strong>Total: {{parseInt(pieReports['cartAdds']['total']) == 1 ? $t('reports.products.one-cart') : pieReports['cartAdds']['total'] + ' ' + $t('reports.products.adds-in-cart')}}</strong>
					</div>
					<template v-if="parseInt(pieReports['cartAdds']['total']) === 0">
						<div class="inactive-chart-header">
							<span class="inactive-chart-mobile">
								<span class="inactive-chart-mobile-color"></span>{{$t('reports.products.mobile')}}</span>
							<span class="inactive-chart-desktop">
								<span class="inactive-chart-desktop-color"></span>Desktop</span>
						</div>
						<img class="inactiveChart" :src="chartInactiv" /> </template>
					<template v-else>
						<pie-chart :options="pieOptions" :width="pieOptions.width" :height="pieOptions.height" :chartData="reactivePieChart('cartAdds')"></pie-chart>
					</template>
				</el-col>
				<el-col :xs="24" :sm="10" :md="6" :lg="6" :xl="6" style="text-align:left">
					<div class="sub-title">
						<strong>Total: {{parseInt(pieReports['favorites']['total']) == 1 ? $t('reports.products.one-favorite') : pieReports['favorites']['total']
							+ ' ' + $t('reports.products.adds-favorites')}}</strong>
					</div>
					<template v-if="parseInt(pieReports['favorites']['total']) === 0">
						<div class="inactive-chart-header">
							<span class="inactive-chart-mobile"><span class="inactive-chart-mobile-color"></span>{{$t('reports.products.mobile')}}</span>
							<span class="inactive-chart-desktop"><span class="inactive-chart-desktop-color"></span>Desktop</span>
						</div>
						<img class="inactiveChart" :src="chartInactiv" />
					</template>
					<template v-else>
						<pie-chart :options="pieOptions" :width="pieOptions.width" :height="pieOptions.height" :chartData="reactivePieChart('favorites')"></pie-chart>
					</template>
				</el-col>
				<el-col :xs="24" :sm="10" :md="6" :lg="6" :xl="6" style="text-align:left">
					<div class="sub-title">
						<strong>Total: {{parseInt(pieReports['listViews']['total']) == 1 ? $t('reports.products.one-search') : pieReports['listViews']['total']
							+ ' ' + $t('reports.products.searches-2')}}</strong>
					</div>
					<template v-if="parseInt(pieReports['listViews']['total']) === 0">
						<div class="inactive-chart-header">
							<span class="inactive-chart-mobile"><span class="inactive-chart-mobile-color"></span>{{$t('reports.products.mobile')}}</span>
							<span class="inactive-chart-desktop"><span class="inactive-chart-desktop-color"></span>Desktop</span>
						</div>
						<img class="inactiveChart" :src="chartInactiv" />
					</template>
					<template v-else>
						<pie-chart :options="pieOptions" :width="pieOptions.width" :height="pieOptions.height" :chartData="reactivePieChart('listViews')"></pie-chart>
					</template>
				</el-col>
			</el-row>
		</div>
		<span slot="footer" class="dialog-footer">
		</span>
	</el-dialog>
</template>

<script>
	import chartInactiv from '@/assets/images/chart_inactiv.png';

	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		getSingleProductChart,
		getSingleProductSummary
	} from '@/api/reports';
	import {
		currentDate,
		lastMonthDate
	} from '@/utils/index';

	const lineChart = () =>
		import ('@/components/Charts/line');

	const pieChart = () =>
		import ('@/components/Charts/pie');
	export default {
		components: {
			'line-chart': lineChart,
			'pie-chart': pieChart
		},
		props: ['productModel', 'visible'],
		data() {
			return {
				pieOptions: {
					responsive: false,
					maintainAspecRation: false,
					height: 200,
					width: 200
				},
				chartInactiv,
				activeReport: 'views',
				reports: {
					views: {

					},
					sells: {

					},
					cartAdds: {

					},
					favorites: {

					},
					listViews: {

					}
				},
				pieReports: {
					views: {
						total: 0
					},
					// sells: {
					// 	chart: {},
					// 	total: 0
					// },
					cartAdds: {
						total: 0
					},
					favorites: {
						total: 0
					},
					listViews: {
						total: 0
					}
				},
				chartOptions: {
					height: '900'
				},
				date: {
					start: lastMonthDate(),
					end: currentDate()
				},
			};
		},
		methods: {
			getChartData(callback) {
				getSingleProductChart({
					type: this.activeReport,
					startDate: this.date.start,
					endDate: this.date.end,
					model: this.productModel
				}).then((res) => {
					if (typeof res === 'undefined' || typeof res.message === 'undefined') return false;
					this.reports[this.activeReport] = this.constructChart(res.message);
					if (typeof callback === 'function') callback();
				});
			},
			constructPieChart(item, index) {
				this.pieReports[index]['chart'] = {};
				const chartDataSet = {
					labels: [this.$t('reports.products.mobile'), 'Desktop'],
					datasets: [{
						backgroundColor: ['#08B4D6', '#3B9B76'],
						data: []
					}]
				};
				chartDataSet.datasets[0].data.push(item['mobile']);
				chartDataSet.datasets[0].data.push(item['desktop']);

				return chartDataSet;
			},
			constructChart(data) {
				this.reports[this.activeReport] = {};
				if (this.activeReport === 'sells') {
					const chartDataSet = {
						labels: [],
						datasets: [{
							label: this.$t('reports.products.sold-products'),
							backgroundColor: '#2bea04',
							data: []
						}]
					};
					for (const day in data) {
						chartDataSet.labels.push(day);
						chartDataSet.datasets[0].data.push(data[day]);
					}
					return chartDataSet;
				} else {
					const chartDataSet = {
						labels: [],
						datasets: [{
								label: 'Total',
								borderColor: '#f56c6ca3',
								backgroundColor: '#ffffff00',
								hidden: true,
								data: []
							},
							{
								label: 'Desktop',
								borderColor: '#3fff2ba3',
								backgroundColor: '#ffffff00',
								data: []
							},
							{
								label: this.$t('reports.products.mobile'),
								borderColor: '#2bb7ffa3',
								backgroundColor: '#ffffff00',
								data: []
							}
						]
					};
					for (const day in data) {
						chartDataSet.labels.push(day);
						chartDataSet.datasets[0].data.push(data[day]['total']);
						chartDataSet.datasets[1].data.push(data[day]['desktop']);
						chartDataSet.datasets[2].data.push(data[day]['mobile']);
					}
					return chartDataSet;
				}
			},
			getPieChartData() {
				getSingleProductSummary({
					model: this.productModel,
					startDate: this.date.start,
					endDate: this.date.end,
				}).then((res) => {
					if (typeof res === 'undefined' || typeof res.message === 'undefined' || Object.keys(res.message).length < 1) {
						return false;
					}
					for (const index in res.message) {
						this.$set(this.pieReports[index], 'chart', this.constructPieChart(res.message[index], index));
						this.pieReports[index]['total'] = res.message[index]['total'];
					}
				});
			},
			handleDateChange(val) {
				this.getChartData(this.getPieChartData);
			},
			reactivePieChart(report) {
				return typeof this.pieReports[report]['chart'] !== 'undefined' && Object.keys(this.pieReports[report]['chart']).length >
					0 ? this.pieReports[report]['chart'] : {};
			},
		},
		computed: {
			reactiveStatus: {
				get() {
					return this.visible;
				},
				set(val) {
					EventBus.$emit('closeModal');
				}
			},
			reactiveChart() {
				return this.reports[this.activeReport];
			},

		},
		watch: {
			activeReport(val) {
				this.getChartData();
			}
		},
		created() {
			this.getChartData();
			this.getPieChartData();
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.pieStats {
		.sub-title {

		}
	}

	.inactiveChart {
		height: 175px;
		margin-top: 5px;
	}

	.inactive-chart-header {
		font-size: 12px;
		margin-top: 21px;
		margin-left: 10px;

		.inactive-chart-mobile {
			.inactive-chart-mobile-color {
				background-color: #F5F5F5;
				position: relative;
				display: inline-block;
				padding: 6px 20px;
				margin-right: 5px;
			}
		}

		.inactive-chart-desktop {
			margin-left: 5px;

			.inactive-chart-desktop-color {
				background-color: #E5E5E5;
				position: relative;
				display: inline-block;
				padding: 6px 20px;
				margin-right: 5px;
			}
		}
	}
</style>